var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
var CaseInfoDistributeService = /** @class */ (function () {
    function CaseInfoDistributeService() {
    }
    /**
     * 分案预览
     */
    CaseInfoDistributeService.prototype.caseDistributedPreview = function (data, loading) {
        return this.netService.send({
            server: businessService.caseInfoDistributeController.caseDistributePreview,
            data: data,
            loading: loading
        });
    };
    /**
     * 待分配分案确认
     */
    CaseInfoDistributeService.prototype.waitCaseDistributeConfirm = function (data, loading) {
        return this.netService.send({
            server: businessService.caseInfoDistributeController.waitCaseDistributeConfirm,
            data: data,
            loading: loading
        });
    };
    /**
     * 机构待分配分案确认
     */
    CaseInfoDistributeService.prototype.distributedCasesConfirm = function (data, loading) {
        return this.netService.send({
            server: businessService.caseInfoDistributeController.distributedCasesConfirm,
            data: data,
            loading: loading
        });
    };
    /**
     * 机构待分配分案预览
     */
    CaseInfoDistributeService.prototype.orgCasesDistributePreview = function (data, loading) {
        return this.netService.send({
            server: businessService.caseInfoDistributeController.orgCasesDistributePreview,
            data: data,
            loading: loading
        });
    };
    /**
     * 获取待分配可分配的批次号以及金额数量
     */
    CaseInfoDistributeService.prototype.getWaitBatchNumberAndTotal = function () {
        return this.netService.send({
            server: businessService.caseInfoDistributeController.getWaitBatchNumberAndTotal
        });
    };
    /**
     * 获取结清案件的批次号
     */
    CaseInfoDistributeService.prototype.getSettleBatchNumber = function () {
        return this.netService.send({
            server: businessService.caseInfoDistributeController.getSettleBatchNumber
        });
    };
    /**
     * 获取机构待分配可分配的批次号以及金额
     */
    CaseInfoDistributeService.prototype.getBatchNumberAndTotal = function (data) {
        return this.netService.send({
            server: businessService.caseInfoDistributeController.getBatchNumberAndTotal,
            data: {
                distributePoolType: data
            }
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseInfoDistributeService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CaseInfoDistributeService.prototype, "caseDistributedPreview", null);
    __decorate([
        Debounce()
    ], CaseInfoDistributeService.prototype, "waitCaseDistributeConfirm", null);
    __decorate([
        Debounce()
    ], CaseInfoDistributeService.prototype, "distributedCasesConfirm", null);
    __decorate([
        Debounce()
    ], CaseInfoDistributeService.prototype, "orgCasesDistributePreview", null);
    __decorate([
        Debounce()
    ], CaseInfoDistributeService.prototype, "getWaitBatchNumberAndTotal", null);
    __decorate([
        Debounce()
    ], CaseInfoDistributeService.prototype, "getSettleBatchNumber", null);
    __decorate([
        Debounce()
    ], CaseInfoDistributeService.prototype, "getBatchNumberAndTotal", null);
    return CaseInfoDistributeService;
}());
export { CaseInfoDistributeService };
