import { render, staticRenderFns } from "./manual-case-allot.vue?vue&type=template&id=a4777a6c&scoped=true"
import script from "./manual-case-allot.vue?vue&type=script&lang=ts"
export * from "./manual-case-allot.vue?vue&type=script&lang=ts"
import style0 from "./manual-case-allot.vue?vue&type=style&index=0&id=a4777a6c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4777a6c",
  null
  
)

export default component.exports